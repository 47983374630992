import '../style/footer.css'
import logo from '../images/keeps.svg'
import { useTranslation } from 'react-i18next';
function Footer(){
    const { t } = useTranslation();
    return(
        <footer className='footer'>
            <div className='footer-body'>
                <p className='footer-text'>{t('footer')}</p>
                <a href='https://www.keep.pt/' target='_blank'><img className="logo" src={logo} alt="KEEP Solutions" /></a>
            </div>
        </footer>
    )
}

export default Footer