import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { getPlugins, searchPlugins, filterPlugins} from "../route/route";
import { useTranslation } from 'react-i18next';
import PluginCard from './PluginCard'
import FiltersCards from './FiltersCard';
import { useSearchParams } from 'react-router-dom';
import '../style/pluginList.css'

function PluginList(props) {
    const [plugins, setPlugins] = useState([])
    const [total, setTotal] = useState([])
    const [filters, setFilters] = useState([]) 
    const [facets, setFacets] = useState([])
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

      
    
    useEffect(() => {
      // make first request to server
      const entries=Object.fromEntries(searchParams.entries())
        if(Object.keys(entries).length === 0)
          getPlugins().then((data) => {
            setPlugins(data.result)
            setTotal(data.total)
            setFacets(data.facets)
          });
      }, []);


      const firstUpdate= useRef(true);
      //function when text boxed is entered
      useLayoutEffect(() => {
        // Prevent from doing first request in render
        if(!firstUpdate.current){
          if (props.searchQuery) {
            searchPlugins(props.searchQuery).then((data) => {
              setPlugins(data.result);
              setTotal(data.total);
              setFacets(data.facets);
            });
          } else {
            getPlugins().then((data) => {
              setPlugins(data.result)
              setTotal(data.total)
              setFacets(data.facets)
            });}
        }
      }, [props.searchQuery]);
      //function when filters are applied
      
      
      useLayoutEffect(() => {
        if(firstUpdate.current){
          firstUpdate.current=false
        }
        else{
          if(!searchParams.get("search"))
          filterPlugins(filters).then((data)=>{
            setPlugins(data.result)
            setTotal(data.total)
            setFacets(data.facets)
          })
        }
      }, [filters]);
    
    
      return (
      <div className="pluginList-container">
        <FiltersCards setFilters={setFilters} searchQuery={props.searchQuery} facets={facets} total ={total}/>
        
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t('type')}</th>
              <th className="nameColumn" scope="col">{t('name')}</th>
              <th scope="col">{t('compatibility')}</th>
              <th scope="col">{t('price')}</th>
              <th scope="col">{t('more')}</th>
            </tr>
          </thead>
          <tbody>
          
          {plugins.length === 0 ? (
              <tr>
                <td colSpan="6" className="noFilters">{t('noItemsFound')}</td>
              </tr>
            ) : (
            plugins.map((plugin) => (
                <PluginCard
                key={plugin.id}
                name={plugin.name}
                type={plugin.type}
                version={plugin.version}
                pricing={plugin.price}
                description={plugin.description}
                pluginInfo={plugin.plugin}
                homepage={plugin.homepage}
                vendorName={plugin.vendor.name}
                linkToQuote={plugin.linkToQuote}
                vendorLink={plugin.vendor.homepage}
                compatibility={plugin.compatibility}
                />
            )))}
          {/*<FacetsCard facets={facets}/>*/}
          </tbody>
        </table>
      </div>
    );
  }
export default PluginList;