import '../style/header.css';
import logo from '../images/roda-logo.svg';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Dropdown } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

function Header() {
  const handleToggle = () => {
    window.open("https://github.com/keeps/roda/blob/master/documentation/Publishing_plugins.md");
  };

  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.language;

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <header>
      <div className="header">
        <div className="header-body">
          <a href={window.location.origin}>
            <img className="logo" src={logo} alt="React Logo" />
          </a>
          <Dropdown className="dropdown-custom">
            <Dropdown.Toggle variant="basic" id="dropdown-basic">
              {t(selectedLanguage)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Button}
                onClick={() => handleLanguageChange('pt')}
              >
                Português
                <span className="dropdown-tick">
                  {selectedLanguage === 'pt' && <FaCheck />}
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                as={Button}
                onClick={() => handleLanguageChange('en')}
              >
                English
                <span className="dropdown-tick">
                  {selectedLanguage === 'en' && <FaCheck />}
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="subheader">
        <div className="subheader-body">
          <p>
            <Trans i18nKey="welcome" components={{ a: <a href="https://www.roda-community.org">RODA Digital Repository</a> }}>
              {t('welcome')}
            </Trans>
          </p>
          <button className="btn btn-primary btn-roda" onClick={handleToggle}>
            {t("contribute")}
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;