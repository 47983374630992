import React, { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../style/searchBox.css';
import glass from '../images/glass.svg';
import { useTranslation } from 'react-i18next';

function SearchBox(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    const searchText = searchParams.get("search");
    if (searchText) {

      props.setSearchQuery(searchText);
    }
  }, [searchParams]);

  const handleInputChange = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value) {
        setSearchParams({ search: event.target.value });
      } else {
        setSearchParams();
      }
      props.setSearchQuery(event.target.value);
    }
  };

  return (
    <div className="input-group">
      <img src={glass} alt="Glass" className="search-icon" />
      <input
        type="search"
        className="form-control rounded"
        placeholder={t('search')}
        aria-label="Search"
        aria-describedby="search-addon"
        value={props.searchQuery}
        onKeyDown={handleInputChange}
      />
    </div>
  );
}

export default SearchBox;