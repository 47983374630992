import React from 'react'
import { useState } from 'react';
import Header from './components/Header'
import SearchBox from './components/SearchBox'
import PluginList from './components/PluginList'
import Footer from './components/Footer'
import './style/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

function App() {
  //<FiltersCards setFilters={setFilters} searchQuery={searchQuery} facets={facets}/>
  const [searchQuery, setSearchQuery] = useState([]);
  return (
    <div className='main'>
      <link href='https://fonts.googleapis.com/css?family=Ubuntu' rel='stylesheet'></link>
      <link href="https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap" rel="stylesheet"></link>
      <link href='https://fonts.googleapis.com/css?family=JetBrains+Mono' rel='stylesheet'></link>
      <Header />
      <div className='body'>
      <SearchBox setSearchQuery={setSearchQuery}/>
      <PluginList searchQuery={searchQuery} />
      </div>
      <Footer />
    </div>
  )
}

export default App