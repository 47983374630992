import { useState,useEffect, useRef} from "react";
import { useSearchParams } from 'react-router-dom';
import '../style/facet.css'
import BadgeComponent from '../images/BadgeComponent.svg';
import BadgePlugin from '../images/BadgePlugin.svg';
import BadgeService from '../images/BadgeService.svg';
import FilterMenu from '../images/FilterMenu.svg';
import { useTranslation } from 'react-i18next';

function FiltersCards(props){
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isChecked = useRef({});
  const firstUpdate = useRef(true); // Prevent useEffect from running on first render
  const [searchParams, setSearchParams] = useSearchParams("");
  const { t } = useTranslation();

  useEffect(() => {
    const searchFilters = searchParams.entries();
  
    // Convert the iterator to an array of key-value pairs
    const filtersArray = Array.from(searchFilters);
  
    if (filtersArray.length > 0) {
      // Build the combined string of key-value pairs
      const filtersString = filtersArray.map(([key, value]) => {
        const isCheckedValue = searchParams.has(key) ? true : false; // Check if the key is present in searchParams
        isChecked.current[`${key}=${value}`] = isCheckedValue; // Store the key-value pair as key in isChecked object
        return `${key}=${value}`;
      }).join('&');
  
      // Set the filters using the combined string
      props.setFilters(filtersString);
    }
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxClick = (event) => {
  
    const checkboxId = event.target.id;
    isChecked.current[checkboxId] = !isChecked.current[checkboxId];
    handleSearchClick();
  
  };

  const handleSearchClick = () => {
    const selectedFilters = Object.keys(isChecked.current).filter((key) => isChecked.current[key]);
    const queryString = selectedFilters.join('&');
    setSearchParams(queryString)
    props.setFilters(queryString);
  };

  useEffect(() => {
    setData(props.facets);
  }, [props.facets]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      isChecked.current = {};
    }
  }, [props.searchQuery]);


  // Capitalize and remove underscores
  const capitalize = (item) => {
    let substrings = item.split("_");
    var capitalizedSubstrings = substrings.map(function(substring) {
      // Capitalize the first letter and concatenate with the rest of the string
      return substring.charAt(0).toUpperCase() + substring.slice(1);
    });
    var result = capitalizedSubstrings.join(' ');
    return result;
  };

  // Pre-process the price tag
  function renderPrice(item) {
    if (item === 'paid') {
      return t('paid');
    } else {
      return item;
    }
  }

  // Pre-process the compatibility tag
  function compatibility(item) {
    return item;
  }

  const renderItems = () => {
    if (Object.values(data).every(arr => arr.length === 0)) {
      return <p>{t('noFilters')}</p>;
    }
  
    return (
      <>
        {Object.entries(data).map(([property, items]) => {
          if (items.length === 0) return null; // Skip empty sections
  
          return (
            <div key={property} className="category-block">
              <div className="sidebar-title">
                <p>{t(property)}</p>
              </div>
              <ul className="items">
                {items.map((item) => {
                  const itemKey = `${property}=${item[property]}`;
                  return (
                    <li key={itemKey} className="item">
                      <label htmlFor={itemKey}>
                        <input
                          className="box"
                          type="checkbox"
                          id={itemKey}
                          checked={!!isChecked.current[itemKey]}
                          onChange={handleCheckboxClick}
                        />
                        {item.type === 'plugin' && <img className="item-logo" src={BadgePlugin} alt="React Logo" />}
                        {item.type === 'component' && <img className="item-logo" src={BadgeComponent} alt="React Logo" />}
                        {item.type === 'service' && <img className="item-logo" src={BadgeService} alt="React Logo" />}
                        
                        {property === 'type' || property === 'category' ? (
                        <span className="item-value">{capitalize(item[property])}</span>
                      ) : property === 'compatibility' ? (
                        <span className="item-value">{compatibility(item[property])}</span>
                      ) : property === 'pricing' ? (
                        <span className="item-value">{renderPrice(item[property])}</span>
                      ) : <span className="item-value">{item[property]}</span>}
                        <span className="item-count item-number">{item.count}</span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </>
    );
  };
  


    return (
      <div className="filters">
        <div className="filters-found">
          <svg className="filter-button" onClick={handleToggle}>
            <image href={FilterMenu} />
          </svg>
          <p className="found-text">{props.total} {t('itemsFound')}</p>
        </div>
        <div className={`sidebar position-fixed bg-light border-end ${isOpen ? 'show' : ''}`}>
          <div className="p-3">
            <div className="sidebar-header">
              <label className="top-title">{t('filters')}</label>
              <button className="btn-close" onClick={handleToggle} />
            </div>
            
            {renderItems()}
            
          </div>
        </div>
      </div>
  );
}



export default FiltersCards;