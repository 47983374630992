const API_ROOT = `${window.__RUNTIME_CONFIG__.API_URL}/api/v2`;

export async function getPlugins() {
  const response = await fetch(`${API_ROOT}/marketplace`)
  const data = await response.json()
  return data
}

export async function searchPlugins(text) {
  const response = await fetch(`${API_ROOT}/marketplace/search?text=${text}`)
  const data = await response.json()
  return data
}

export async function filterPlugins(filters) {
  const response = await fetch(`${API_ROOT}/marketplace/filtered?${filters}`)
  const data = await response.json()
  return data
}
