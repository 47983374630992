import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../style/pluginCard.css'
import VectorBlue from '../images/Vector.svg';
import VectorWhite from '../images/VectorWhite.svg';
import BadgePlugin from '../images/BadgePlugin.svg'
import BadgeComponent from '../images/BadgeComponent.svg'
import BadgeService from '../images/BadgeService.svg'

function PluginCard(props) {

  const [isHovered, setIsHovered] = useState(false);
  const [image, setImage] = useState(props.type)
  const { t, i18n } = useTranslation();

  const languageLinks = {
    en: props.linkToQuote.en,
    pt: props.linkToQuote.pt,
  };

  const currentLanguage = i18n.language;
  const linkToPay = languageLinks[currentLanguage];

  useEffect(() => {

    if (props.type === 'plugin'){
      setImage(BadgePlugin)
    } else if (props.type === 'service') {
      setImage(BadgeService)
    } else if (props.type === 'component') {
      setImage(BadgeComponent)
    }

  });

  const handleMouseEnterPrice = (event) => {
    event.target.innerText = t("getQuote").toUpperCase()
  };

  const handleMouseLeavePrice = (event) => {
    event.target.innerText = t('paid')
  };

  const handleMouseEnterMore = () => {
    setIsHovered(true)
  };  

  const handleMouseLeaveMore = () => {
    setIsHovered(false)
  };
  
  const openPluginInfoPage = () => {
    window.open(props.homepage, '_blank')
  };

  const capitalize = (item) => {
    let substrings = item.split("_");
    var capitalizedSubstrings = substrings.map(function(substring) {
      // Capitalize the first letter and concatenate with the rest of the string
      return substring.charAt(0).toUpperCase() + substring.slice(1);
    });
    var result = capitalizedSubstrings.join(' ');
    return result;
  };

  const cardClass = `pluginCard${props.type === 'plugin' ? ' cardTypePlugin' : ''}`;  

  return (
  <tr className={cardClass}>
    <td>
      <div className="columnContent type"><img src={image} title={t(props.type)} alt="type"/></div>
    </td>
    <td>
      <div className="text">
        <h3 className="pluginName">
          {props.version === '' ? (
            props.name
          ) : (
            <>
              <span>{props.name}</span>
              <div className="version" title={t('versionTitle', { v: props.version})}>{props.version}</div>
            </>
          )}
        </h3>
        <a className='anchor' href={props.vendorLink} target="_blank"><h5 className="pluginVendor">{props.vendorName}</h5></a>
        <p className="pluginDescription">{props.description}</p>
        {props.pluginInfo.categories.map((category, index) => (
          <div key={index} title={t('categoryTitle', {c: capitalize(category)})} className="pluginCategory">
            {category.toUpperCase().replace('_', ' ')}
          </div>
        ))}        
      </div>
    </td>
    <td>
      <div className="columnContent compability" title={t('compabilityTitle', { v1: props.compatibility })}>
        <div style={{ textAlign: props.compatibility.length === 1 ? 'center' : 'left' }}>
          {props.compatibility.map((value, index) => (
            <React.Fragment key={index}>
              {index > 0 && <div style={{ marginBottom: '2px' }} />}
              {value.trim()}
            </React.Fragment>
          ))}
        </div>
      </div>   
    </td>   
    <td>
      <div className="columnContent">
        {props.pricing === 'paid' ? (
          <a href={linkToPay + encodeURIComponent(props.name)} target="_blank">
            <button
              className="btn btn-primary-price"
              onMouseEnter={handleMouseEnterPrice}
              onMouseLeave={handleMouseLeavePrice}>
                {t('paid')}
            </button>
          </a>   
        ) : (
          <div className="priceNumber">{props.pricing}</div>
        )}
      </div>
    </td>    
    <td className="more"
      title={t('moreTitle')}
      onMouseEnter={handleMouseEnterMore}
      onClick={openPluginInfoPage}
      onMouseLeave={handleMouseLeaveMore}>
      <div className="columnContent">
          <img
            src={isHovered ? VectorWhite : VectorBlue} 
            alt="More" 
          />
      </div>
    </td>      
  </tr>
  );
  }
  export default PluginCard;